import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g id="Logo" transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="#64FFDA"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <g transform="translate(21.000000, 28.000000)">
        	<g xmlns="http://www.w3.org/2000/svg" transform="translate(0,-1020.3622)">
				<g transform="matrix(-0.3,0,0,0.3,128.26569,1005.7626)">
					<path d="M 464.92239,125.15655 A 30,30 0 0 1 405,123" transform="matrix(0.94375907,0,0,1,-36.222423,-11.001304)" stroke="#64FFDA" strokeWidth="20.566668"
        strokeLinecap="round"
        strokeMiterLimit="0"
        />
				<rect width="19" height="60" rx="5" ry="5" x="336.5" y="56" fill="#64FFDA"/>
				</g>
			</g>
        </g>
	</g>
	</g>
   </svg>
);

export default IconLogo;
