module.exports = {
  siteTitle: 'Jeff Conaway | Full Stack Software Engineer',
  siteDescription:
    'Full stack software engineer with extensive experience in the design and development of data driven, responsive, web based application systems.',
  siteKeywords:
    'Jeff Conaway, Jeff, Conaway, hellifiknow, software engineer, database administrator, full stack engineer, web developer, javascript, remote',
  siteUrl: 'https://jeffconaway.me',
  siteLanguage: 'en_US',

  googleVerification: 'aMWTm1ln2D9dk_G0gRv6zSdOH2KnvfPWAVy8EHV22sQ',

  name: 'Jeff Conaway',
  location: 'Flowery Branch, GA',
  email: 'jeff@jeffconaway.me',
  socialMedia: [
    {
      name: 'Github',
      url: 'https://github.com/hellifiknow/',
    },
    {
      name: 'Codepen',
      url: 'https://codepen.io/hellifiknow/',
    },
  ],

  nav: [
    {
      name: 'About',
      url: '#about',
    },
    {
      name: 'Experience',
      url: '#jobs',
    },
    {
      name: 'Work',
      url: '#projects',
    },
    {
      name: 'Contact',
      url: '#contact',
    },
  ],

  twitterHandle: '',
  googleAnalyticsID: 'UA-130237710-2',

  headerHeight: 100,

  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => {
    return {
      origin: 'bottom',
      distance: '20px',
      duration: 500,
      delay,
      rotate: { x: 0, y: 0, z: 0 },
      opacity: 0,
      scale: 1,
      easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      mobile: true,
      reset: false,
      useDelay: 'always',
      viewFactor: 0.25,
      viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
    };
  },
};
